import axiosInstance from "./axios";

export const loginApi = async (data) => {
  return await axiosInstance.post(`admin/auth/login`, data);
};

export const createSubAdminApi = async (data) => {
  return await axiosInstance.post(`admin/auth/create/admin`, data);
};

export const getSubAdminListApi = async (data) => {
  return await axiosInstance.get(`admin/auth/get-admins`, data);
};
export const adminStatusUpdateApi = async (data) => {
  return await axiosInstance.put(`admin/auth/update-status-admin`, data);
};

export const deleteSubAdminApi = async (id) => {
  return await axiosInstance.delete(`admin/auth/delete-admin/${id}`);
};
export const getAdminStatusApi = async () => {
  return await axiosInstance.get(`admin/get-online-status`);
};
export const updateAdminStatusApi = async (data) => {
  return await axiosInstance.put(`admin/update-online-status`, data);
};
export const changePasswordApi = async (data) => {
  return await axiosInstance.post(`admin/auth/change-password`, data);
};
export const getAdminNotificationApi = async (data) =>{
  return await axiosInstance.get(`admin/get-admin-notification?page=${data?.page}&limit=${data?.limit}`);
}
export const getAndUpdateNotificationApi = async (data) =>{
  return await axiosInstance.post(`admin/notification-count-update`, data);
}