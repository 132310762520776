/**
 * @file This file contains all the Arabic language translations
 * Arabic Language
 * @type {Object}
 * @returns {Object} language - The language object
 */

import header from "./ar/header.json";
import common from './ar/common.json';

const ar = () => {
  const language = {
    ...header,
    ...common,
  };
  return language;
}

export default ar