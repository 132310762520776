import axiosInstance from "./axios";

export const getDashboardDataApi = async (data) => {
  return axiosInstance.get(
    `admin/get-dashboard-data?startDate=${data?.startDate}&endDate=${data?.endDate}`
  );
};
export const getRatingAndBookingCountApi = async (data) => {
  return axiosInstance.get(
    `admin/get-rating-order-data?startDate=${data?.startDate}&endDate=${data?.endDate}`
  );
}
export const getCatGDataApi = async (data) => {
  return axiosInstance.get(
    `admin/get-order-cat-wise?startDate=${data?.startDate}&endDate=${data?.endDate}`
  );
}
export const getBookingWithStatusChartApi = async (data) => {
  return axiosInstance.get(
    `admin/get-booking-chart-status?startDate=${data?.startDate}&endDate=${data?.endDate}&cat_id=${data?.cat_id}`
  );
};