export const ONBOARDING_APIS = {
    LOGIN: '/admin/auth/login',
    REFRESH_TOKEN: '/admin/auth/refresh_token',
    CHANGE_PASSWORD: '/admin/user/change-password'
  }
  export const ADMIN_APIS = {
    Category_List: '/category/getCategory',
    Category_Details: `/category/getCat/`,
    Category_Add: '/category/addCategory',
    Category_Update: '/category/update/',
    SubCategory_Add: '/category/addSubCategory',
    SubCategory_Update: '/category/update-subcategory/',
    Category_Customunit: '/category/addCustomUnit',
    Category_Customfield: '/category/addCustomField',
    Category_Status_Update: '/category/update-category-status/',
    Subategory_Status_Update: '/category/update-subcategory-status/',
  }
  export const storageKeys = {
    ADMIN_TOKEN: 'adminaccessToken',
    USER_DATA: 'userData'
  }
  