import Routing from "./Route/routing";
import React, { useState, useEffect } from 'react';

const App = () => {

  const [hasDropdown, setHasDropdown] = useState(false);

  useEffect(() => {
    const body = document.body;
    const observer = new MutationObserver(() => {
      const divWithClass = body.querySelector('.p-connected-overlay-enter-done');
      setHasDropdown(!!divWithClass);
    });

    observer.observe(body, {
      attributes: true,
      childList: true,
      subtree: true,
    });

    return () => {
      observer.disconnect();
    };
  }, []);

  useEffect(() => {
    if (hasDropdown) {
      body.classList.add('overflow-hidden');
    } else {
      body.classList.remove('overflow-hidden');
    }
  }, [hasDropdown]);

  return (
    <>
      <Routing />
    </>
  );
};

export default App;
