import React, { useState } from 'react';
import { ProgressSpinner } from 'primereact/progressspinner';
import './loadingComp.css'; // CSS file for styling

const FullPageLoading = () => {
  
  return (
    <div className={`full-page-loading  show`}>
    <div className="spinner-container">
    </div>
  </div>
  );
};

export default FullPageLoading;
