import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import "primeicons/primeicons.css";

const NotificationPopup = ({ open, setOpen }) => {
  return (
    <Dialog
      header={"Sound Notification"}
      className="dialogWidth400 headetPadding"
      visible={open}
      style={{ width: "400px" }}
      //   onHide={() => setOpen(false)}
      closable={false}
      draggable={false}
      footer={({ hide }) => (
        <div className="dialogFooter-2 p2 dilogbtns w-100">
          <Button
            className="btn btn-orange w-100"
            onClick={() => {
              setOpen(false);
            }}
          >
            Allow sound
          </Button>
        </div>
      )}
    >
      <div className="addformDialog pt-3">
        <p className="mb-3 mt-0" style={{ textAlign: "center" }}>
          Your notification sound is disabled. Please enable notifications to
          receive sound alerts.
        </p>
      </div>
    </Dialog>
  );
};
export default NotificationPopup;
