import React, { useState } from "react";
import { Button } from "primereact/button";
import { Sidebar } from "primereact/sidebar";
import { Password } from "primereact/password";
// images

// other
import "./header.scss";
import { changePasswordApi } from "../../../service/auth.service";
import { toast } from "react-toastify";
import { Spinner } from "react-bootstrap";
const ChangePasswordSidebar = ({ open, setOpen, data }) => {
  const [value, setValue] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState({});
  const handleChangePassword = async () => {
    try {
      
      if(loading) return;
      if(!value?.password||!value?.new_password||!value?.c_password||value?.new_password!==value?.c_password  || value?.new_password?.length<8 || value?.c_password?.length<8){
        let errors = {};
        if(!value?.password) errors.password = "Old password is required";
        if(!value?.new_password) errors.new_password = "New password is required";
        if(!value?.c_password) errors.c_password = "Confirm password is required";
        if(value?.new_password!==value?.c_password) errors.c_password = "Password and confirm password should be same";
        if(value?.new_password?.length<8) errors.new_password = "Password should be at least 8 characters";
        if(value?.c_password?.length<8) errors.c_password = "Password should be at least 8 characters";
        setError(errors);
        return;
      }
      setError({});
      setLoading(true);
      let res = await changePasswordApi(value);
      if (res?.status === 200) {
        toast.success(res?.data?.message);
        setOpen(false);
        setValue({});
      }else{
        toast.error(res?.data?.message);
      }
      
      setLoading(false);
    } catch (error) {
      setLoading(false);
      if (typeof error?.response?.data?.message === "string") {
        toast.error(error?.response?.data?.message);
      } else {
        toast.error(error?.message || "Internal server error");
      }
    }
  };
  const handleClose = () => {
    setOpen(false);
    setValue({});
    setError({});
  }
  return (
    <Sidebar
      className="detailSiderbar change-password"
      visible={open}
      position="right"
      onHide={handleClose}
    >
      <h4 className="mt-0 sidebarTitleHd">Change Password</h4>
      <div className="sidebarContentBody">
        <div className="grid">
          <div className="lg:col-12 md:col-12 sm:col-12">
            <div className="formField">
              <span className="p-float-label">
                <Password
                  value={value?.password}
                  onChange={(e) =>
                    setValue({ ...value, password: e.target.value })
                  }
                  toggleMask
                  feedback={false}
                />
                <label>
                  Old Password <span className="requiredstar">*</span>
                </label>
                <p className="p-error">{error?.password}</p>
              </span>
            </div>
          </div>
          <div className="lg:col-12 md:col-12 sm:col-12">
            <div className="formField">
              <span className="p-float-label">
                <Password
                  value={value?.new_password}
                  onChange={(e) =>
                    setValue({ ...value, new_password: e.target.value })
                  }
                  toggleMask
                  autoComplete="off"
                />
                <label>
                  New Password <span className="requiredstar">*</span>
                </label>
                <p className="p-error">{error?.new_password}</p>
              </span>
            </div>
          </div>
          <div className="lg:col-12 md:col-12 sm:col-12">
            <div className="formField">
              <span className="p-float-label">
                <Password
                  value={value?.c_password}
                  onChange={(e) => setValue({...value,c_password:e.target.value})}
                  toggleMask
                  feedback={false}
                />
                <label>
                  Confirm Password <span className="requiredstar">*</span>
                </label>
                <p className="p-error">{error?.c_password}</p>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div className="sidebarFooter">
        <div className="btnsRow">
          <Button className="btn btn-gray" onClick={handleClose}>
            Cancel
          </Button>
          <Button className="btn btn-orange" onClick={handleChangePassword}>
            {loading ? <Spinner/>:"Save"}
          </Button>
        </div>
      </div>
    </Sidebar>
  );
};
export default ChangePasswordSidebar;
