import React from "react";
import { ROLES } from "../utils/constant";
import { Navigate } from "react-router-dom";

const SalesRoute = ({ children, roles }) => {
  return (
    <>
      {roles == ROLES.ADMIN || roles == ROLES.SALES ? (
        <>{children}</>
      ) : (
        <Navigate to={"/"} />
      )}
    </>
  );
};
export default SalesRoute;
