import "./header.scss";
import { Button } from "primereact/button";
import { Dialog } from "primereact/dialog";
import { useState } from "react";
import { Spinner } from "react-bootstrap";
import { toast } from "react-toastify";
import { updateAdminStatusApi } from "../../../service/auth.service";

const OnlineOfflineDialog = ({ open, setOpen, status, setStatus }) => {
  const [loading, setLoading] = useState(false);
  const handleStatus = async () => {
    try {
      if (loading) return;
      setLoading(true);
      let isStatus = status ? "offline" : "online";
      let res = await updateAdminStatusApi({ status: isStatus });
      if (res?.status === 200) {
        setOpen(false);
        setStatus(!status);
        toast.success(res?.data?.message);
      }else{
        toast.error(res?.data?.message);
      }
      setLoading(false);
    } catch (err) {
      setLoading(false);
      if (typeof err?.response?.data?.message === "string") {
        toast.error(err?.response?.data?.message);
      } else {
        toast.error("Something went wrong");
      }
    }
  };
  return (
    <Dialog
      header="Admin Online Status"
      className="dialogWidth400 headetPadding"
      visible={open}
      style={{ width: "400px" }}
      onHide={() => setOpen(false)}
      draggable={false}
      footer={({ hide }) => (
        <div className="dialogFooter-2 p2 dilogbtns w-100">
          <Button
            className="btn btn-gray w-100"
            onClick={() => {
              setOpen(false);
            }}
          >
            No
          </Button>
          <Button className="btn btn-orange w-100" onClick={handleStatus}>
            {loading ? <Spinner /> : "Yes"}
          </Button>
        </div>
      )}
    >
      <div className="addformDialog pt-3">
        <p className="p1 mb-3 mt-0" style={{ textAlign: "center" }}>
          Are you sure want to go {status ? "offline":"online"} this?
        </p>
      </div>
    </Dialog>
  );
};
export default OnlineOfflineDialog;
