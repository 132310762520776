/**
 * @file This file contains all the English language translations
 * English Language
 * @type {Object}
 * @returns {Object} language - The language object
 */

import header from "./en/header.json";
import common from './en/common.json';

const en = () => {
  const language = {
    ...header,
    ...common,
  };
  return language;
}

export default en